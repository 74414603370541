import domainImageMapping from '../assets/page-text/DomainAndImageMapping.json';
import { Domain, Subdomain } from '../model/models';
import { breakpoints, colors } from '../styles';
import levelMappingToColor from '../styles/levelMappingToColor';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

interface SummaryDisplayProps {
    /**
     * Total size of the element (in pixels)
     */
    height?: number;

    /**
     * Total size of the element (in pixels)
     */
    width?: number;

    /**
     * Padding for the sides (in pixels)
     */
    sideMargin?: number;

    /**
     * Width of the circle tick (in pixels)
     */
    tickWidth?: number;

    /**
     * Circle radius (in pixels)
     */
    radius?: number;

    domainSpacing?: number;

    /**
     * Center Image url
     */
    centerImage?: string;

    className?: string;
    domains: Domain[];
    withTitle?: boolean;
    levels_state?: any;
    survey_id: any;
}

const SummaryScreenDisplayWrapper = styled.svg`
    ${breakpoints.onlyMobile} {
        margin-left: 20px;
    }
`;

const SummaryScreenProgressWrapper = styled.g``;

const SummaryPolygon = styled.polygon<{ color: string }>`
    fill: ${(props) => props.color};
`;

const getXCoordinateForPoint = (
    circleRadius: number,
    step: number,
    totalAmountOfArcs: number,
): number =>
    circleRadius *
    Math.cos((step * 2 * Math.PI) / totalAmountOfArcs - Math.PI / 2);

const getYCoordinateForPoint = (
    circleRadius: number,
    step: number,
    totalAmountOfArcs: number,
): number =>
    circleRadius *
    Math.sin((step * 2 * Math.PI) / totalAmountOfArcs - Math.PI / 2);

const getPolygonPoints = (radius: number, domains: Domain[]): string => {
    const points = domains.reduce((acc, domain: Domain, index, array) => {
        return (acc += `${getXCoordinateForPoint(
            radius,
            index,
            array.length,
        )} ${getYCoordinateForPoint(radius, index, array.length)}${
            index !== array.length - 1 ? ',' : ''
        } `);
    }, '');
    return points;
};

const SummaryDomainLevelIndicator = styled.g`
    display: flex;
`;
const SummaryDomainImage = styled.image`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SummaryDomainTitle = styled.text`
    font-size: 10px;
    fill: ${colors.textColor1};
    font-weight: bold;
`;

/**
 * Taken
 */
const splitter = (str: string): string[] => {
    const strs = [];
    const l = 14;
    while (str.length > l) {
        let pos = str.substring(0, l).lastIndexOf(' ');
        pos = pos <= 0 ? l : pos;
        strs.push(str.substring(0, pos));
        let i = str.indexOf(' ', pos) + 1;
        if (i < pos || i > pos + l) i = pos;
        str = str.substring(i);
    }
    strs.push(str);
    return strs;
};

const SummaryDomainLevel = styled.text`
    font-size: 14px;
    fill: ${colors.black};
    font-weight: bold;
`;

const getDomainAverageLevel = (domain: Domain): number => {
    return (
        Math.round(
            (domain.subdomains.reduce(
                (acc: any, subdomains: Subdomain) =>
                    acc + (subdomains.level || 0),
                0,
            ) /
                domain.subdomains.filter((r) => r.level !== null).length) *
                10,
        ) / 10
    );
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SummaryDomainLevelCircle = styled.circle``;

const SummarySpiderLine = styled.line`
    stroke-width: 2;
`;

const SummarySpiderTarget = styled.circle`
    stroke-width: 1;
    fill: none;
`;

const SummarySpiderTargetInner = styled.circle``;

const SummaryDisplay: React.FC<SummaryDisplayProps> = ({
    height = 700,
    width = 1024,
    tickWidth = 5,
    sideMargin = 0,
    domainSpacing = 35,
    domains,
    className,
    withTitle = true,
    levels_state,
    survey_id,
}) => {
    const radius = width > breakpoints.value.mobile ? width / 2.5 - sideMargin - 30 : height / 2 - sideMargin - 30;
    // const radius = 200;
    const drawingAndBoxSpacing = width > breakpoints.value.mobile ? 60 : 40;
    const textWidth = 30;
    const marginImageText = 3;
    const imageSize = 40;

    // const levels_color = levels_state.levels_data.map((item: any) => item.color);

    const [levelsConfig, setLevelsConfig] = useState<any>(null);
    const [levelsData, setLevelsData] = useState<any>(null);
    const [levels_color, setLevelsColor] = useState<any>({ '1': '2' });

    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_levels`,{
                headers: {
                    'Survey-Id': survey_id || ''
                }
            });
            const data = await response.json();
            setLevelsConfig(data);
            setLevelsData(data.levels_data.reverse());

            const co: any = {};
            for (let i = 0; i < data.levels_data.length; i++) {
                co[i + 1] = data.levels_data[i].color;
            }

            const new_co: any = [];
            Object.keys(co)
                .sort((a: any, b: any) => a - b)
                .forEach((key) => {
                    new_co.push(co[key]);
                });
            new_co.reverse();

            const newDict = {};
            for (let i = 0; i < new_co.length; i++) {
                co[i] = new_co[i];
            }

            setLevelsColor(co);
        }
        fetchConfig();
    }, []);


    const boxWidth =
        // textWidth +
        imageSize + marginImageText;

    const viewBoxWidth =  width > breakpoints.value.mobile ? width+80 : width

    /**
     * For the image to be in the middle, it has to be smaller than the whole svg
     */
    return (
        <SummaryScreenDisplayWrapper
            width={width}
            height={height}
            viewBox={`0 0 ${viewBoxWidth} ${height}`}
            className={className}
        >
            <SummaryScreenProgressWrapper
                transform={`translate(${width / 2}, ${height / 2})`}
                strokeWidth={tickWidth}
                width={width}
                height={height}
            >
                {/* <SummaryPolygon
                    color={levelMappingToColor[4]}
                    fill={levelMappingToColor[4]}
                    points={getPolygonPoints(radius - boxWidth, domains)}
                /> */}
                {/* <SummaryPolygon
                    color={levelMappingToColor[3]}
                    fill={levelMappingToColor[3]}
                    points={getPolygonPoints(
                        radius - boxWidth - domainSpacing,
                        domains,
                    )}
                />
                <SummaryPolygon
                    color={levelMappingToColor[2]}
                    fill={levelMappingToColor[2]}
                    points={getPolygonPoints(
                        radius - boxWidth - domainSpacing * 2,
                        domains,
                    )}
                />
                <SummaryPolygon
                    color={levelMappingToColor[1]}
                    fill={levelMappingToColor[1]}
                    points={getPolygonPoints(
                        radius - boxWidth - domainSpacing * 3,
                        domains,
                    )}
                /> */}
                {levelsData &&
                    levelsData.map((level: any, index: any) => {
                        const spacing_multiple = 4 - levelsData.length;

                        if (index === 0) {
                            return (
                                <SummaryPolygon
                                    key={index}
                                    color={level.color}
                                    fill={level.color}
                                    points={getPolygonPoints(
                                        radius -
                                            boxWidth -
                                            domainSpacing *
                                                (index + spacing_multiple),
                                        domains,
                                    )}
                                />
                            );
                        } else {
                            return (
                                <SummaryPolygon
                                    key={index}
                                    color={level.color}
                                    fill={level.color}
                                    points={getPolygonPoints(
                                        radius -
                                            boxWidth -
                                            domainSpacing *
                                                (index + spacing_multiple),
                                        domains,
                                    )}
                                />
                            );
                        }
                    })}

                {domains.map((domain: Domain, index: number, array) => {
                    const textToRight = index > array.length / 2;

                    return (
                        <SummaryDomainLevelIndicator
                            key={domain.title}
                            fill={colors.white}
                            transform={`translate(${
                                getXCoordinateForPoint(
                                    radius +
                                        8 -
                                        boxWidth +
                                        drawingAndBoxSpacing,
                                    index,
                                    array.length,
                                ) -
                                imageSize / 2
                            }, ${
                                getYCoordinateForPoint(
                                    radius +
                                        8 -
                                        boxWidth +
                                        drawingAndBoxSpacing,
                                    index,
                                    array.length,
                                ) -
                                imageSize / 2
                            })`}
                        >
                            <SummaryDomainImage
                                href={domainImageMapping[domain.idx]}
                                width={imageSize}
                                height={imageSize}
                            />
                            {withTitle && (
                                <Fragment>
                                    <SummaryDomainTitle
                                        x={
                                            textToRight
                                                ? -textWidth - marginImageText
                                                : imageSize + marginImageText
                                        }
                                        y={-imageSize / 2}
                                        fill={colors.blueText}
                                    >
                                        {splitter(domain.title).map(
                                            (textPiece, indexText) => (
                                                <tspan
                                                    key={`${indexText}-text`}
                                                    x={
                                                        textToRight
                                                            ? -textWidth -
                                                              marginImageText
                                                            : imageSize +
                                                              marginImageText
                                                    }
                                                    y={indexText * 15}
                                                    dy="1.2em"
                                                >
                                                    {textPiece}
                                                </tspan>
                                            ),
                                        )}
                                    </SummaryDomainTitle>
                                    <SummaryDomainLevelCircle
                                        cx={
                                            textToRight
                                                ? -textWidth - marginImageText
                                                : imageSize + marginImageText
                                        }
                                        cy={
                                            (splitter(domain.title).length +
                                                1) *
                                            15
                                        }
                                        r={3}
                                        fill={
                                            levels_color[
                                                Math.floor(
                                                    getDomainAverageLevel(
                                                        domain,
                                                    ),
                                                )
                                            ]
                                        }
                                    />
                                    <SummaryDomainLevel
                                        x={
                                            textToRight
                                                ? -textWidth -
                                                  marginImageText +
                                                  10
                                                : imageSize +
                                                  marginImageText +
                                                  10
                                        }
                                        text-anchor={
                                            index >= array.length / 2
                                                ? 'end'
                                                : 'start'
                                        }
                                        y={
                                            3 +
                                            (splitter(domain.title).length +
                                                1) *
                                                15
                                        }
                                    >
                                        {getDomainAverageLevel(domain)}
                                    </SummaryDomainLevel>
                                </Fragment>
                            )}
                        </SummaryDomainLevelIndicator>
                    );
                })}

                {/* Spider */}
                {domains.map((domain: Domain, index: number, array) => {
                    const targetRadius =
                        radius -
                        boxWidth -
                        domainSpacing / 2 -
                        domainSpacing *
                            (4 - Math.floor(getDomainAverageLevel(domain)));
                    return (
                        <Fragment key={`frag-${domain.title}`}>
                            <SummarySpiderLine
                                key={`spider-line-${domain.title}`}
                                x1={0}
                                stroke={colors.libertyMediumTeal}
                                y1={0}
                                x2={getXCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                                y2={getYCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                            />
                            <SummarySpiderTarget
                                r={4}
                                key={`outertarget-spider-line-${domain.title}`}
                                stroke={colors.libertyMediumTeal}
                                stroke-width={2}
                                cx={getXCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                                cy={getYCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                            />
                            <SummarySpiderTargetInner
                                r={2}
                                key={`innertarget-spider-line-${domain.title}`}
                                fill={colors.libertyMediumTeal}
                                cx={getXCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                                cy={getYCoordinateForPoint(
                                    targetRadius,
                                    index,
                                    array.length,
                                )}
                            />
                        </Fragment>
                    );
                })}
            </SummaryScreenProgressWrapper>
        </SummaryScreenDisplayWrapper>
    );
};
export default SummaryDisplay;
