import i18n from 'i18next';
import decode from 'jwt-decode';

const myTokenUrl = process.env.REACT_APP_MYTOKEN_URL || '';
const refreshTokenUrl = process.env.REACT_APP_REFRESH_TOKEN_URL || '';
const accessTokenLocalStorageName = 'mytech_access_token';

function get(
    url: string,
    surveyId: any,
    token?: string,
    lang?: string,
    uid?: string,
): Promise<any> {
    const myHeaders = new Headers();
    myHeaders.append('Survey-Id', surveyId);
    if (token) myHeaders.append('Authorization', `Bearer ${token}`);
    if (lang) myHeaders.append('Accept-language', lang);
    if (uid) myHeaders.append('adid', uid);
    return new Promise(
        (resolve, reject): Promise<any> =>
            fetch(url, {
                method: 'GET',
                headers: myHeaders,
            })
                .then((resp: Response): void => {
                    if (!resp.ok) reject(resp.status);
                    resolve(resp);
                })
                .catch((err: Error): void => reject(err)),
    );
}

function getJson(
    url: string,
    surveyId: any,
    token?: string,
    userId?: string,
    lang?: string,
    uid?: string,
): Promise<any> {
    const myHeaders = new Headers();
    myHeaders.append('Survey-Id', surveyId);
    if (userId) myHeaders.append('user_id', userId);
    if (token) myHeaders.append('Authorization', `Bearer ${token}`);
    if (lang) myHeaders.append('Accept-language', lang);
    if (uid) myHeaders.append('adid', uid);

    return new Promise(
        (resolve, reject): Promise<any> =>
            fetch(url, {
                method: 'GET',
                headers: myHeaders,
            })
                .then((resp: Response): void => {
                    if (!resp.ok) reject(resp.status);
                    resp.json()
                        .then((data: any): void => {
                            resolve(data);
                        })
                        .catch((err: Error): void => reject(err));
                })
                .catch((err: Error): void => reject(err)),
    );
}

function postJson(
    url: string,
    surveyId: any,
    body: object,
    token?: string,
    userId?: string,
    uid?: string,
): Promise<Response> {
    const myHeaders = new Headers();
    myHeaders.append('Survey-Id', surveyId);
    myHeaders.append('Content-Type', 'application/json');
    if (userId) myHeaders.append('user_id', userId);
    if (token) myHeaders.append('Authorization', `Bearer ${token}`);
    if (uid) myHeaders.append('adid', uid);

    return new Promise(
        (resolve, reject): Promise<void> =>
            fetch(url, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(body),
            })
                .then((resp: Response): void => {
                    if (!resp.ok) reject(resp.status);
                    resolve(resp);
                })
                .catch((err: Error): void => reject(err)),
    );
}

function putJson(
    url: string,
    surveyId: any,
    body: object,
    token?: string,
    userId?: string,
    uid?: string,
): Promise<Response> {
    const myHeaders = new Headers();
    myHeaders.append('Survey-Id', surveyId);
    myHeaders.append('Content-Type', 'application/json');
    if (userId) myHeaders.append('user_id', userId);
    if (uid) myHeaders.append('adid', uid);
    if (token) myHeaders.append('Authorization', `Bearer ${token}`);
    return new Promise(
        (resolve, reject): Promise<void> =>
            fetch(url, {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(body),
            })
                .then((resp: Response): void => {
                    if (!resp.ok) reject(resp.status);
                    resolve(resp);
                })
                .catch((err: Error): void => reject(err)),
    );
}

async function getMyInfos(surveyId: any): Promise<any> {
    const jwt = localStorage.getItem(accessTokenLocalStorageName);

    if (jwt) {
        const decoded: any = decode(jwt);
        const expiracy: number = decoded.exp;
        // Is expired if less than 5 mns on the token
        const isExpired =
            new Date(expiracy * 1000).getTime() - new Date().getTime() <=
            5 * 60 * 1000;
        if (!isExpired) {
            return {
                token: jwt,
            };
        }
    }
    let myInfos;
    try {
        await get(refreshTokenUrl, surveyId);
        myInfos = await getJson(myTokenUrl, surveyId);

        localStorage.setItem(
            accessTokenLocalStorageName,
            myInfos[0].access_token,
        );
    } catch (err) {
        return err;
    }
    return {
        token: myInfos[0].access_token,
    };
}

async function authenticatedPostJson(
    url: string,
    surveyId: any,
    payload: object,
    uid?: string,
): Promise<any> {
    let result;
    try {
        const token = '';
        result = await postJson(url, surveyId, payload, token, undefined, uid);
    } catch (err) {
        return err;
    }
    return result;
}

async function authenticatedGetJson(url: string, surveyId: any, uid?: string): Promise<any> {
    const lang = i18n.language;

    let result;
    try {
        const token = '';

        result = await getJson(url, surveyId, token, undefined, lang, uid);
    } catch (err) {
        return err;
    }
    return result;
}

async function authenticatedPutJson(
    url: string,
    surveyId: any,
    payload: object,
    uid?: string,
): Promise<any> {
    let result;
    try {
        const token = '';
        result = await putJson(url, surveyId, payload, token, undefined, uid);
    } catch (err) {
        return err;
    }
    return result;
}

async function authenticatedGet(url: string, surveyId: any,  uid?: string): Promise<any> {
    const lang = i18n.language;
    let result;
    try {
        const token = '';

        result = await get(url, surveyId, token, lang, uid);
    } catch (err) {
        return err;
    }
    return result;
}

export {
    getJson,
    postJson,
    authenticatedGetJson,
    authenticatedGet,
    authenticatedPostJson,
    authenticatedPutJson,
};
