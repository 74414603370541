/* eslint-disable prettier/prettier */
import { validateFeedback } from '../model/joi.models';
import { Feedback, FeedbackAnswer } from '../model/models';
import { State } from '../redux-state';
import { authenticatedGetJson, authenticatedPostJson } from '../utils/requests';
import {
    FEEDBACK_ANSWER_ERROR,
    FEEDBACK_ANSWER_SUCCESS,
    FEEDBACK_FETCH_ERROR,
    FEEDBACK_FETCH_LOADING,
    FEEDBACK_FETCH_SUCCESS,
    FeedbackAnswerError,
    FeedbackAnswerSuccess,
    FeedbackFetchError,
    FeedbackFetchLoading,
    FeedbackFetchSuccess,
} from './feedbackTypes';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

const feedbackFetchError = (error: Error): FeedbackFetchError => {
    return {
        type: FEEDBACK_FETCH_ERROR,
        error: true,
        payload: {
            error,
            firstTimeLoading: false,
        },
    };
};

const feedbackFetchSuccess = (feedback: Feedback): FeedbackFetchSuccess => {
    return {
        type: FEEDBACK_FETCH_SUCCESS,
        payload: feedback,
    };
};

const feedbackAnswerSuccess = (): FeedbackAnswerSuccess => {
    return {
        type: FEEDBACK_ANSWER_SUCCESS,
        payload: {},
    };
};

const feedbackAnswerError = (error: Error): FeedbackAnswerError => {
    return {
        type: FEEDBACK_ANSWER_ERROR,
        error: true,
        payload: {
            error,
        },
    };
};

const setFeedbackFetchLoading = (
    loadingStatus: boolean,
): FeedbackFetchLoading => {
    return {
        type: FEEDBACK_FETCH_LOADING,
        payload: {
            loading: loadingStatus,
        },
    };
};

const feedbackFetchUnauthorizedError = (error: Error): FeedbackFetchError => {
    return {
        type: FEEDBACK_FETCH_ERROR,
        error: true,
        payload: {
            error,
        },
    };
};

/**
 * Get the VALIDATED Feedback
 */
const getFeedback = (surveyId: any, uid: any): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        let feedback: Feedback;
        dispatch(setFeedbackFetchLoading(true));
        try {
            const resp = await authenticatedGetJson(
                `${backendUrl}/feedback`,
                surveyId,
                uid,
            );
            if (resp == 401) {
                return dispatch(
                    feedbackFetchUnauthorizedError(new Error('Unauthorized')),
                );
            }
            feedback = validateFeedback(resp);
        } catch (err) {
            return dispatch(feedbackFetchError(err as Error));
        }

        dispatch(feedbackFetchSuccess(feedback));
        dispatch(setFeedbackFetchLoading(false));
    };
};

const submitFeedback = (
    answers: Array<FeedbackAnswer>,
    surveyId: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        const answerUrl = `${backendUrl}/feedback`;

        try {
            await authenticatedPostJson(answerUrl, answers, uid);
        } catch (err) {
            return dispatch(feedbackAnswerError(err as Error));
        }
        dispatch(feedbackAnswerSuccess());
        dispatch(getFeedback(surveyId, uid));
    };
};

export { getFeedback, submitFeedback };
