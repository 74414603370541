import { breakpoints, colors, fonts } from '../styles';
import { globalColor } from '../utils/globals';
import useViewport from '../utils/useViewport';
import { AnimateItem } from './Animate';
import CircleIcon from './CircleIcon';
import HelpPopin from './HelpPopin';
import ProgressControls from './ProgressControls';
import QuestionText from './QuestionText';
import SurveyPageLayout from './SurveyPageLayout';
import React, { useState } from 'react';
import styled from 'styled-components';

const TextSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    ${breakpoints.onlyMobile} {
        text-align: center;
    }
    ${breakpoints.sm} {
        padding-right: 60px;
    }
    ${breakpoints.md} {
        padding-right: 60px;
    }
    ${breakpoints.lg} {
        padding-right: 60px;
    }
    ${breakpoints.largeDesktop} {
        padding-right: 60px;
    }
    ${breakpoints.largerDesktop} {
        padding-right: 60px;
    }
`;

const Title = styled.div`
    ${breakpoints.onlyMobile} {
        justify-content: space-between;
        display: flex;
    }
    ${fonts.content};
    color: ${_ => globalColor.titleColor};
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const Question = styled.div`
    font-size: 26px;
    ${breakpoints.onlyMobile} {
        font-size: 1.5em;
    }
    color: ${colors.libertyBlue};
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 32px;
`;

const IllustrationSide = styled.div`
    box-sizing: border-box;
    padding-left: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const PositionedCircleIcon = styled(CircleIcon)`
    align-self: flex-end;
`;

const Illustration = styled.div`
    align-self: center;
`;

const SizedProgressControls = styled(ProgressControls)`
    width: 150px;
    align-self: center;
`;

const HelpIndicator = styled.div`
    margin-top: 1em;
    ${fonts.content};
    color: ${colors.libertyBlue};
    text-decoration: underline;
`;

const ProgressIndicator = styled.div`
    ${fonts.content};
    color: ${colors.libertyBlue};
`;

const TargetSample = styled.div`
    color: ${colors.libertyBlue};
    font-size: 18px;
    ${breakpoints.onlyMobile} {
        font-size: 1em;
    }
    margin-bottom: 32px;

    span {
        font-weight: bold;
    }
`;

const HelpContent = styled.div`
    display: flex;
    position: absolute;
    top: 30px;
`;

const HelpContentWapper = styled.div`
    justify-content: center;
    display: flex;
`;

interface SurveyPageProps {
    title: string;
    questionPhrase: string;
    questionDomainExplanation: string;
    questionDomain: string;
    doneText?: string;
    helpText?: string;
    progress: number;
    progressTotal: number;
    nextClick?: () => void;
    backClick?: () => void;
    illustrationSideContent?: React.ReactElement;
    showHelpByDefault?: boolean;
    targetSample?: string;
    shouldShowTooltip?: boolean;
    children?: React.ReactNode;
    logoImage: any;
    shouldShowQuestionPhrase?: boolean;
}

const SurveyPageTemplate: React.FC<SurveyPageProps> = ({
    title,
    questionPhrase,
    shouldShowQuestionPhrase = true,
    questionDomainExplanation,
    questionDomain,
    progress,
    progressTotal,
    doneText,
    helpText,
    children,
    nextClick,
    backClick,
    illustrationSideContent,
    showHelpByDefault = false,
    targetSample,
    logoImage,
    shouldShowTooltip = true,
}): React.ReactElement => {
    const [showHelp, setShowHelp] = useState(showHelpByDefault);
    const { width } = useViewport();
    return showHelp ? (
        <HelpPopin onClose={() => setShowHelp(false)} />
    ) : (
        <SurveyPageLayout logoImage={logoImage}>
            <TextSide>
                <Title>
                    {width <= breakpoints.value.mobile && backClick && (
                        <CircleIcon
                            onClick={() => backClick()}
                            type={'previousMobile'}
                            active={true}
                        />
                    )}
                    {title}
                    {width <= breakpoints.value.mobile && (
                        <ProgressIndicator>
                            {progress.toString()}/{progressTotal.toString()}
                        </ProgressIndicator>
                    )}
                </Title>
                <AnimateItem changeOn={questionDomain}>
                    <Question>
                        {doneText || (
                            <QuestionText
                                shoudShowTooltip={shouldShowTooltip}
                                shouldShowQuestionPhrase={
                                    shouldShowQuestionPhrase
                                }
                                questionPhrase={questionPhrase}
                                questionDomain={questionDomain}
                                questionDomainExplanation={
                                    questionDomainExplanation
                                }
                            />
                        )}
                    </Question>
                    {targetSample && (
                        <TargetSample
                            dangerouslySetInnerHTML={{
                                __html: targetSample,
                            }}
                        />
                    )}
                    {children}
                    {width <= breakpoints.value.mobile && (
                        <HelpIndicator onClick={() => setShowHelp(true)}>
                            {helpText}
                        </HelpIndicator>
                    )}
                </AnimateItem>
            </TextSide>
            {width > breakpoints.value.mobile && (
                <IllustrationSide>
                    <HelpContentWapper>
                        <HelpContent>
                            <div
                                style={{
                                    padding: '5px',
                                    fontSize: '14px',
                                    color: 'white',
                                    marginTop: '8px',
                                }}
                            >
                                {'Skill Proficiency Definition'}
                            </div>
                            <CircleIcon
                                type={'help'}
                                active={true}
                                backgroundColor={colors.white}
                                onClick={() => setShowHelp(true)}
                            />
                        </HelpContent>
                    </HelpContentWapper>
                    <Illustration>{illustrationSideContent}</Illustration>
                    <SizedProgressControls
                        count={progress}
                        total={progressTotal}
                        onClickForward={nextClick}
                        onClickBackward={backClick}
                    />
                </IllustrationSide>
            )}
        </SurveyPageLayout>
    );
};

export default SurveyPageTemplate;
