import defaultMiddleImage from '../assets/people-intro.png';
import { getSummaryPDF } from '../actions/surveyActions';
import arrow from '../assets/svg/arrow.svg';
import Button from '../components/Button';
import CircleIcon from '../components/CircleIcon';
import ClickableDomainsList from '../components/ClickableDomainsList';
import InformationLayout from '../components/InformationLayout';
import SummaryDisplay from '../components/SummaryDisplay';
import { Domain } from '../model/models';
import { State } from '../redux-state';
import { breakpoints, containerStyles, fonts, colors } from '../styles';
import { globalColor } from '../utils/globals';
import useViewport from '../utils/useViewport';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';


interface MainContentProps {
    leftSize: number;
    withButton: boolean;
}

interface NextStepContainerProps {
    leftSize: number;
}

interface ImageSideCSSProps {
    leftSize: number;
}


const SummaryPageWrapper = styled.div`
    ${containerStyles.fullPage};
    ${breakpoints.onlyMobile} {
        padding: 20px;
    }
    background: ${globalColor.backgroundColor1};
`;

const SummaryPageContent = styled.div`
    ${breakpoints.md} {
        ${containerStyles.fullPageContent};
    }
    display: flex;
    flex-direction: column;
    ${breakpoints.onlyMobile} {
        width: 100%;
    }
`;

const Logo = styled.img`
    width: 250px;
    height: auto;
    margin-bottom: 50px;
`;

const Title = styled.div`
    ${fonts.subtitle};
    font-size: 24px;
    color: ${() => globalColor.titleColor};
    font-weight: bold;
    ${breakpoints.onlyMobile} {
        display: flex;
        justify-content: space-around;
    }
    margin-bottom: 30px;
`;

const PositionedNavLink = styled(NavLink)`
    margin-top: 1em;
    align-self: center;
`;

const LogoAndCaptureRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
`;

const WithNotificationContainer = styled.div`
    position: absolute;
    top: 150px;
    right: 100px;
`;

const NotificationPanel = styled.div`
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    color: ${colors.black};
    top: 20px;
    right: 20px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 15px;
    ${fonts.indication};
`;

const ArrowIndicator = styled.div`
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    z-index: 1;
    right: 10px;
    transform: rotate(135deg);
`;

const SizedButton = styled(Button)`
    margin-top: 16px;
    width: 70px;
    height: 40px;
    font-size: 14px;
`;

const TextSizer = styled.div`
    width: 160px;
    font-size: 14px;
`;

const LogoImage = styled.img`
    max-width: 25%;
    max-height: 25%;
    width: 100%;
`;

const ImageSide = styled.div<ImageSideCSSProps>`
    display: flex;
    align-items: center;
    position: relative;
    margin-left:30px;
    width: ${(props) => 100 - props.leftSize -5}%;
`;


const DownloadCircleIcon = styled(CircleIcon)`
    height: 28px;
    width: 28px;
    border-radius: 28px;
    margin-top: 4px;
`;

const SummaryTitleWrapper = styled.div`
    ${fonts.subtitle};
    width: 640;
    margin: 0 auto;
    ${breakpoints.onlyMobile} {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
    }
    margin-bottom: 30px;
`;

const SummaryTitle = styled.div`
    ${fonts.subtitle};
    color: ${colors.black};
    font-weight: bold;
    width: 70%;
    ${breakpoints.onlyMobile} {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
    }
    margin-bottom: 30px;
`;

const NotEnoughDomainIndicatorWrapper = styled.div`
    width: 70%;
    margin: 0 auto;
    ${breakpoints.onlyMobile} {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
    }
`;

const NotEnoughDomainIndicator = styled.div`
    ${fonts.content};
    height: 400px;
    color: ${colors.textColor1};
`;

const getDomainsToDisplay = (domains: Domain[]) =>
    domains.filter(
        (domain: Domain) =>
            domain.completed &&
            !domain.mandatory &&
            domain.preEvaluationLevel &&
            domain.preEvaluationLevel > 0,
    );

const SummaryPage: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    /**
     * Bind all domains to this container
     */
    const domains: Domain[] = useSelector(
        (state: State): Domain[] => state.surveyState.domains,
    );

    const { width } = useViewport();
    const graphWidth = width > breakpoints.value.mobile ? 640 : 350;
    const graphHeight = width > breakpoints.value.mobile ? 600 : 350;
    const { survey_id, uid } = useParams();

    const LogoImage = styled.img`
        max-height: 100%;
        width: 14.3%;
        margin-right: 10%;
        ${breakpoints.onlyMobile} {
            max-width: 25%;
            max-height: 100%;
            width: 100%;
            margin-right: 0%;
        }
    `;

    const MiddleImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    top: 200px;
`;

const TextContent = styled.div<NextStepContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    ${breakpoints.md} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    ${breakpoints.lg} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    > * {
        &:nth-child(1) {
            margin-right: 20px;
        }
    }
`;

    const MainContent = styled.div<MainContentProps>`
    display: flex;
    > * {
        &:nth-child(1) {
            ${breakpoints.onlyMobile} {
                width: 100%;
                padding-right: 0;
            }
            width: ${(props) => props.leftSize}%;
            padding-right: 60px;
        }
        &:nth-child(2) {
            height: ${(props) =>
                props.withButton
                    ? containerStyles.maxHeight - containerStyles.nextStepHeight
                    : containerStyles.maxHeight}px;
        }
    }
`;

    const LogoImageContainer = styled.div`
         {
            display: flex;
            justify-content: flex-end;
        }
    `;
    const [downloadIndicator, setDownloadIndicator] = useState(true);
    const logoImage = '/talentbuilderlogo_black.png';

    const { t } = useTranslation(['Summary']);
    let emailAuth;
    let levels_state;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;

    if (stateVar) {
        if ('levels_state' in stateVar) {
            levels_state = stateVar.levels_state;
        }
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }

    useEffect(() => {
        if (!stateVar) {
            navigate(`/${survey_id}/${uid}`, { replace: true });
        }
    }, []);
    const logoImageSrc: string = useSelector((state: State): string => state.surveyState.logoSrc || '',);
    const leftSize = 55;
    const withButton = true;
    return !stateVar ? (
        <></>
    ) : (
    <InformationLayout
        leftSize={55}
        leftSideBackgroundColor={globalColor.backgroundColor1}
        rightSideBackgroundColor={globalColor.backgroundColor2}
        withButton={true}
        canOverflow={false}
        logoImage={logoImage}
    >

              <MainContent withButton={withButton} leftSize={leftSize}>
                    <TextContent leftSize={leftSize}>
                    {/* <SummaryPageContent> */}
                {width > breakpoints.value.mobile ? (
                    <SummaryTitleWrapper>
                        <LogoAndCaptureRow>
                            <WithNotificationContainer>
                                <CircleIcon
                                    active={true}
                                    backgroundColor={colors.white}
                                    onClick={() => dispatch(getSummaryPDF(survey_id, uid))}
                                    type="download"
                                />
                                {downloadIndicator && (
                                    <NotificationPanel>
                                        <TextSizer>{t('content')}</TextSizer>
                                        <SizedButton
                                            onClick={() =>
                                                setDownloadIndicator(false)
                                            }
                                            shadow={false}
                                        >
                                            {t('gotIt')}
                                        </SizedButton>
                                        <ArrowIndicator />
                                    </NotificationPanel>
                                )}
                            </WithNotificationContainer>
                        </LogoAndCaptureRow>
                        <Title>{t('title')}</Title>
                    </SummaryTitleWrapper>
                ) : (
                    <Title>
                        {t('title')}
                        <DownloadCircleIcon
                            active={true}
                            onClick={() => dispatch(getSummaryPDF(survey_id, uid))}
                            type="download"
                        />
                    </Title>
                )}
                {getDomainsToDisplay(domains).length < 3 ? (
                    <NotEnoughDomainIndicatorWrapper>
                        <NotEnoughDomainIndicator
                            dangerouslySetInnerHTML={{
                                __html: t('notEnoughDomainsMessage'),
                            }}
                        />
                    </NotEnoughDomainIndicatorWrapper>
                ) : (
                    <SummaryDisplay
                        domains={getDomainsToDisplay(domains)}
                        height={graphHeight}
                        width={graphWidth}
                        withTitle={width > breakpoints.value.mobile}
                        className="chart-img"
                        levels_state={levels_state}
                        survey_id={survey_id}
                    />
                )}
                {width <= breakpoints.value.mobile && (
                    <ClickableDomainsList
                        survey_id={survey_id}
                        uid={uid}
                        showScore={width <= breakpoints.value.mobile}
                        domains={domains
                            .filter(
                                (domain: Domain) =>
                                    domain.mandatory ||
                                    (domain.preEvaluationLevel &&
                                        domain.preEvaluationLevel > 0),
                            )
                            .sort((a, b) => (b.completed ? -1 : 1))}
                    />
                )}
                <PositionedNavLink
                    to={`/${survey_id}/${uid}/${t('nextViewSlug') || ''}`}
                    state={{ emailAuth: emailAuth }}
                >
                    <Button>{t('nextStepText')}</Button>
                </PositionedNavLink>
            {/* </SummaryPageContent> */}
                    </TextContent>
                    {width > breakpoints.value.mobile && (
                        <ImageSide leftSize={leftSize}>
                            <MiddleImage src={defaultMiddleImage} />
                        </ImageSide>
                    )}
                </MainContent>

       
    </InformationLayout>
    );
};

export default SummaryPage;
