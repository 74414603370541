import { updateUserCompleted } from '../actions/surveyActions';
import logo from '../assets/logoTitle.png';
import ClickableDomainsList from '../components/ClickableDomainsList';
import EditMenu from '../components/EditMenu';
import Illustration from '../components/Illustration';
import SurveyPageLayout from '../components/SurveyPageLayout';
import { Domain } from '../model/models';
import { State } from '../redux-state';
import { colors, fonts, containerStyles, breakpoints } from '../styles';
import useViewport from '../utils/useViewport';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { globalColor } from '../utils/globals';

interface ProgressIndicatorCSSProps {
    done: boolean;
}

const TextSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    ${breakpoints.md} {
        padding-right: 60px;
    }
`;

const Title = styled.div`
    ${fonts.subtitle};
    color: ${_ => globalColor.titleColor};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const Greetings = styled.div`
    ${fonts.content};
    color: ${colors.textColor1};
    margin-bottom: 50px;
`;

const Logo = styled.img`
    width: 300px;
    height: auto;
    margin-bottom: 20px;
`;

const IllustrationSide = styled.div`
    box-sizing: border-box;
    padding-left: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const PositionedEditMenu = styled(EditMenu)`
    align-self: flex-end;
`;

const SurveyWelcomeProgress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${breakpoints.onlyMobile} {
        margin-top: 1.5em;
    }
`;

const ProgressIndicator = styled.div<ProgressIndicatorCSSProps>`
    width: 200px;
    height: 50px;
    background: none;
    border: ${(props) => (!props.done ? `2px solid ${colors.white}` : 'none')};
    width: ${containerStyles.nextStepHeight}px;
    height: 55px;
    background-color: ${(props) =>
        props.done ? colors.blueDark : globalColor.backgroundColor2};
    color: ${colors.white};
    ${fonts.content};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
`;

const ProgressMessage = styled.div`
    ${fonts.content};
    color: ${colors.textColor1};
    max-width: 235px;
    text-align: center;
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: ${colors.white};
`;


const getCompletedDomainsNumberAsString = (
    domains: Domain[],
    domainDefaultProficiency?: any,
): string => {
    return domains
        .reduce((total, domain: Domain) => {
            return domainDefaultProficiency
                ? domain.completed
                    ? total + 1
                    : total
                : domain.completed &&
                  domain.preEvaluationLevel != null &&
                  domain.preEvaluationLevel > 0
                ? total + 1
                : total;
        }, 0)
        .toString();
};

const getDomainsToCompleteNumberAsString = (
    domains: Domain[],
    domainDefaultProficiency?: any,
): string => {
    return domains
        .reduce((total, domain: Domain) => {
            return domainDefaultProficiency
                ? total + 1
                : domain.preEvaluationLevel != null &&
                  domain.preEvaluationLevel > 0
                ? total + 1
                : total;
        }, 0)
        .toString();
};

const hasFilledAllDomains = (domains: Domain[]) => {
    return (
        getCompletedDomainsNumberAsString(domains) ===
        getDomainsToCompleteNumberAsString(domains)
    );
};

const SurveyWelcomePage: React.FC = () => {
    /**
     * Bind all domains to this container
     */
    const domains: Domain[] = useSelector(
        (state: State): Domain[] => state.surveyState.domains,
    );

    const domainDefaultProficiency: number | undefined = useSelector(
        (state: State): number | undefined =>
            state.surveyState.domainDefaultProficiency || undefined,
    );

    const feedbackFeatureEnabled: boolean = useSelector(
        (state: State): boolean =>
            state.surveyState.feedbackFeatureEnabled || false,
    );

    const summaryFeatureEnabled: boolean = useSelector(
        (state: State): boolean  => state.surveyState.summaryFeatureEnabled || false,
    );


    const dispatch = useDispatch();

    const { width } = useViewport();

    const [showRGPD, setShowRGPD] = useState(false);

    const { t } = useTranslation(['SurveyWelcome']);
    const logoImage = '/talentbuilderlogo.png';

    const { survey_id, uid } = useParams();

    let emailAuth: any;
    let levels_state: any;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;

    const performNext = (success: boolean) => {
        if (success) {
            navigate(
                `/${survey_id}/${uid}/${summaryFeatureEnabled ? 'summary' : 'whatsnext'
                }`,
                {
                    state: {
                        levels_state: levels_state,
                        emailAuth: emailAuth,
                    },
                },
            );
        }
    };

    if (stateVar) {
        if ('levels_state' in stateVar) {
            levels_state = stateVar.levels_state;
        }
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }

    useEffect(() => {
        if (!stateVar) {
            navigate(`/${survey_id}/${uid}`, { replace: true });
        }
    }, []);

    return !stateVar ? (
        <></>
    ) : (
        <SurveyPageLayout logoImage={logoImage}>
            <TextSide>
                {/* <Logo src={logo} alt="'CLIENT' IT" /> */}
                <Title>
                    {hasFilledAllDomains(domains) ? t('doneTitle') : t('title')}
                </Title>
                <Greetings
                    dangerouslySetInnerHTML={{
                        __html: hasFilledAllDomains(domains)
                            ? t('doneGreetingsText')
                            : t('greetingsText'),
                    }}
                />
                <ClickableDomainsList
                    survey_id={survey_id}
                    uid={uid}
                    levels_state={levels_state}
                    emailAuth={emailAuth}
                    domains={domains
                        .filter((domain: Domain) =>
                            domainDefaultProficiency
                                ? domain != null
                                : domain.mandatory ||
                                  (domain.preEvaluationLevel &&
                                      domain.preEvaluationLevel > 0),
                        )
                        .sort((a, b) => (b.completed ? -1 : 1))}
                />
                {width <= breakpoints.value.mobile && (
                    <SurveyWelcomeProgress>
                        <ProgressIndicator done={hasFilledAllDomains(domains)}>
                            {hasFilledAllDomains(domains) ? (
                                <StyledNavLink
                                    to={
                                        feedbackFeatureEnabled
                                            ? `/${survey_id}/${uid}/feedback`
                                            : `/${survey_id}/${uid}/${summaryFeatureEnabled? 'summary' : 'whatsnext'}`
                                    }
                                    state={{
                                        levels_state: levels_state,
                                        emailAuth: emailAuth,
                                    }}
                                    onClick={(e) => {
                                        if (!feedbackFeatureEnabled) {
                                            e.preventDefault();
                                            dispatch(updateUserCompleted(survey_id, uid, performNext),);
                                        }
                                    }}
                                >
                                    {feedbackFeatureEnabled
                                        ? t('goToNextPage')
                                        : t('goToMySummaryText')}
                                </StyledNavLink>
                            ) : (
                                <Fragment>
                                    {t('completion')
                                        .replace(
                                            '<DOMAINS_COMPLETED>',
                                            getCompletedDomainsNumberAsString(
                                                domains,
                                            ),
                                        )
                                        .replace(
                                            '<DOMAINS_AMOUNT>',
                                            getDomainsToCompleteNumberAsString(
                                                domains,
                                            ),
                                        )}
                                </Fragment>
                            )}
                        </ProgressIndicator>
                        {!hasFilledAllDomains(domains) && (
                            <ProgressMessage>
                                {t('completionRequirement')}
                            </ProgressMessage>
                        )}
                    </SurveyWelcomeProgress>
                )}
            </TextSide>
            {width > breakpoints.value.mobile && (
                <IllustrationSide>
                    <PositionedEditMenu
                        overviewPath={`/${survey_id}/${uid}/pre-survey`}
                        informationsPath={`/${survey_id}/${uid}/more-info`}
                        openRGPD={() => setShowRGPD(false)}
                        tutorialPath={`/${survey_id}/${uid}/tutorial`}
                        levels_state={levels_state}
                        emailAuth={emailAuth}
                    />

                    <Illustration
                        survey_id={survey_id}
                        uid={uid}
                        canNavigate={true}
                        domains={domains}
                        levels_state={levels_state}
                        domainDefaultProficiency={domainDefaultProficiency}
                    />
                    <SurveyWelcomeProgress>
                        <ProgressIndicator done={hasFilledAllDomains(domains)}>
                            {hasFilledAllDomains(domains) ? (
                                <StyledNavLink
                                    to={
                                        feedbackFeatureEnabled
                                            ? `/${survey_id}/${uid}/feedback`
                                            : `/${survey_id}/${uid}/${summaryFeatureEnabled? 'summary' : 'whatsnext'}`
                                    }
                                    state={{
                                        levels_state: levels_state,
                                        emailAuth: emailAuth,
                                    }}
                                    onClick={(e) => {
                                        if (!feedbackFeatureEnabled) {
                                            e.preventDefault();
                                            dispatch(updateUserCompleted(survey_id, uid, performNext),);
                                        }
                                    }}
                                >
                                    {feedbackFeatureEnabled
                                        ? t('goToNextPage')
                                        : t('goToMySummaryText')}
                                </StyledNavLink>
                            ) : (
                                <Fragment>
                                    {t('completion')
                                        .replace(
                                            '<DOMAINS_COMPLETED>',
                                            getCompletedDomainsNumberAsString(
                                                domains,
                                            ),
                                        )
                                        .replace(
                                            '<DOMAINS_AMOUNT>',
                                            getDomainsToCompleteNumberAsString(
                                                domains,
                                            ),
                                        )}
                                </Fragment>
                            )}
                        </ProgressIndicator>
                        {!hasFilledAllDomains(domains) && (
                            <ProgressMessage>
                                {t('completionRequirement')}
                            </ProgressMessage>
                        )}
                    </SurveyWelcomeProgress>
                </IllustrationSide>
            )}
        </SurveyPageLayout>
    );
};

export default SurveyWelcomePage;
