import Explanation from '../components/Explanation';
import InformationPageTemplate from '../components/InformationPageTemplate';
import { colors, breakpoints } from '../styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { State } from '../redux-state';
import { globalColor } from '../utils/globals';

const Explanations = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    ${breakpoints.sm} {
        flex-direction: row;
        > * {
            width: 33%;
        }
        margin-top: 0;
    }
    ${breakpoints.lg} {
        flex-direction: row;
        > * {
            width: 33%;
        }
        margin-top: 0;
    }
    ${breakpoints.md} {
        flex-direction: row;
        > * {
            width: 33%;
        }
        margin-top: 0;
    }
    ${breakpoints.largeDesktop} {
        flex-direction: row;
        > * {
            width: 33%;
        }
        margin-top: 0;
    }
    ${breakpoints.largerDesktop} {
        flex-direction: row;
        > * {
            width: 33%;
        }
        margin-top: 0;
    }
`;

const SizedExplanation = styled(Explanation)`
    padding: 20px 50px;
    box-sizing: border-box;
    justify-content: flex-start;
    border-radius: 16px;
    margin: 1em;
    background-color: ${props => globalColor.titleColor};
`;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TutorialPage: React.FC = () => {
    const { t } = useTranslation(['Tutorial']);
    const { survey_id, uid } = useParams();
    const domainDefaultProficiency: number | undefined = useSelector(
        (state: State): number | undefined =>
            state.surveyState.domainDefaultProficiency || undefined,
    );

    const contentTranslations = t('content') as unknown as any[];

    const [skillCluster, setSkillCluster] = useState<any>('');
    const [skills, setSkills] = useState<any>('');
    const [levelsCount, setLevelsCount] = useState<any>('');
    const [maxLevel, setMaxLevel] = useState<any>('');
    const [minLevel, setMinLevel] = useState<any>('');

    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_skills_metadata`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Survey-Id':survey_id || '',
                }});
            const data = await response.json();

            setSkillCluster(data.rows.domain_count);
            setSkills(data.rows.subdomain_count);
            setLevelsCount(data.rows.levels_count);
            setMinLevel(data.rows.min_answer_level);
            setMaxLevel(data.rows.max_answer_level);
        }

        fetchConfig();
    }, []);

    let emailAuth;
    let levels_state;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;
    if (!stateVar || !stateVar.emailAuth) {
        navigate(`/${survey_id}/${uid}`, { replace: true });
    } else {
        emailAuth = stateVar.emailAuth;
        levels_state = stateVar.levels_state;
    }

    return (
        <InformationPageTemplate
            leftSize={100}
            leftSideBackgroundColor={globalColor.backgroundColor1}
            rightSideBackgroundColor={globalColor.backgroundColor1}
            title={t('title') || ''}
            tagline={t('tagline') || ''}
            content={
                <Explanations>
                    {contentTranslations.map((explanation: any, index: any) => (
                        <SizedExplanation
                            key={`explanation-${index}`}
                            title={t(explanation.title).replace(
                                '{levelsCount}',
                                levelsCount,
                            )}
                            image={explanation.image}
                            description={t(explanation.description)
                                .replace('{skillCluster}', skillCluster)
                                .replace('{skillsCount}', skills)
                                .replace('{levelsCount}', levelsCount)
                                .replace('{min_answer_level}', minLevel)
                                .replace('{max_answer_level}', maxLevel)}
                        />
                    ))}
                </Explanations>
            }
            nextText={t('nextText') || ''}
            // previousPath={`/${t('previousViewSlug')}`}
            // nextPath={`/${t('nextViewSlug')}`}
            previousPath={`/${survey_id}/${uid}/${t('previousViewSlug')}`}
            nextPath={`/${survey_id}/${uid}/${
                domainDefaultProficiency ? 'survey-welcome' : t('nextViewSlug')
            }`}
            shouldNavForward={false}
            levels_state={levels_state}
            logoImage="/talentbuilderlogo_black.png"
            emailAuth={emailAuth}
        />
    );
};

export default TutorialPage;
