import DisclaimerPageTemplate from '../components/DisclaimerPageTemplate';
import { State } from '../redux-state';
import isSSO from '../utils/checkIfSSOType';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DisclaimerPage: React.FC = (): React.ReactElement => {
    const { t } = useTranslation(['Disclaimer']);

    const { survey_id, uid } = useParams();

    const [client_name, setClientName] = useState<any>('');
    const [project_name, setProjectName] = useState<any>('');
    const surveyCompleted = useSelector(
        (state: State) => state.surveyState.userInfos.surveyCompleted,
    );
    const summaryFeatureEnabled: boolean = useSelector(
        (state: State): boolean  => state.surveyState.summaryFeatureEnabled || false,
    );

    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_client_info`, {
                headers: {
                    'Survey-Id': survey_id || ''
                }
            });
            const data = await response.json();
            setClientName(data.rows[0].client);
            setProjectName(data.rows[0].project_name);
        }
        fetchConfig();
    }, []);
    // const old_content = t('content');

    // const new_subtitle = client_name.replace(/_/g, ' ');
    const updatedContent = t(isSSO() ? 'contentSSO' : 'content', {
        CLIENT: client_name,
    });
    const newContent = updatedContent.replace(/_/g, ' ');
    return (
        <DisclaimerPageTemplate
            title={t('title') || ''}
            tagline={t('tagline') || ''}
            content={newContent || ''}
            nextText={t('nextText') || ''}
            middleImage={t('middleImage') || ''}
            logoImage={t('logoImage') || ''}
            nextPath={
                surveyCompleted == 1 ? (summaryFeatureEnabled ? 'summary' : 'whatsnext' ) : `${t('nextViewSlug')}`
            }
            uid={uid}
            survey_id={survey_id || project_name}
            validateEmailText={`${t('validateEmail')}`}
            helpEmailText={`${t('helpEmail')}`}
            emailPlaceholder={`${t('emailPlaceholder')}`}
        />
    );
};

export default DisclaimerPage;
